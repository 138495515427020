import { App, AppDB } from "../db";

export default class OrderModel {
    static async getAllOders() {
        try {
            const docsOfOrders = await AppDB.collection("orders").get();
            return docsOfOrders.docs
                .map((doc) => ({ ...doc.data(), ref: doc.ref }))
                .sort((a, b) => b.tx_id - a.tx_id);
        } catch (error) {
            return [];
        }
    }

    static async getSingleOrder(productId) {
        try {
            const doc = await AppDB.doc(`/orders/${productId}`).get();
            if (!doc.exists) throw new Error("Product not found");
            return { ...doc.data(), ref: doc.ref };
        } catch {
            return undefined;
        }
    }

    /**
     *
     * @param {string} status
     */
    static async updateStatus(status, order) {
        const batch = AppDB.batch();
        const products = order.products;
        const orderRef = order.ref;
        const userHistoryRefOrder = order.orderRefFromUser;
        const updatedProducts = [];
        const orderUserInfo = order.userInfo;
        const userAffId = orderUserInfo.affiliationId ?? null;
        const usersCollectionRef = AppDB.collection("users");
        const mainRef = AppDB.collection("main").doc("alifrax_data");
        const userRef = order.userInfo.ref;
        try {
            for (const product of products) {
                product.status = status;
                updatedProducts.push(product);
            }
            await AppDB.runTransaction(async (trans) => {
                if (status === "delivered") {

                    const affUsersProdPromises = [];
                    const accountsMoney = {};
                    const mainCollection = (await trans.get(mainRef)).data() ?? {};
                    let userAffDoc = null;
                    if (userAffId){
                        const getUserAffDoc = await trans.get(usersCollectionRef.doc(userAffId));
                        userAffDoc = getUserAffDoc.data();
                    }
                    const userData = (await trans.get(userRef)).data();
                    products.forEach((prod) => {
                        if (prod.affiliationId)
                            affUsersProdPromises.push(
                                trans.get(usersCollectionRef.doc(prod.affiliationId))
                            );
                    });

                    const affUsersProdAccounts = await Promise.all(affUsersProdPromises);
                   
                    products.forEach((prod) => {
                        const margin = prod.margin ?? 0;
                        const userMoney = (margin * 20) / 100;
                        const affMoney = userMoney / 2;

                        const mainNet = margin - userMoney - affMoney;
                        if (mainCollection.balance) mainCollection.balance += mainNet;
                        else mainCollection.balance = mainNet;
                        
                        const currentAffId = prod.affiliationId;
                        if (currentAffId) {
                            const getUserFromAffAccounts = affUsersProdAccounts.find(doc => doc.id === prod.affiliationId);
                            if (!getUserFromAffAccounts) throw new Error("data corrupted");
                            const userFromAffAccountBalance = getUserFromAffAccounts.balance ?? 0;
                            const updatedBalance = userFromAffAccountBalance + affMoney;
                            if (accountsMoney[currentAffId]) accountsMoney[currentAffId] += updatedBalance;
                            else accountsMoney[currentAffId] = updatedBalance;
                        }
                        else if (userAffDoc) {
                            const userFromAffAccountBalance = userAffDoc?.balance ?? 0;
                            const updatedBalance = userFromAffAccountBalance + affMoney;
                            if (accountsMoney[userAffId]) accountsMoney[userAffId] += updatedBalance;
                            else accountsMoney[userAffId] = updatedBalance;
                        }
                        const userUpdatedBalance = (userData.balance ?? 0) + userMoney;
                        if (accountsMoney[userRef.id]) accountsMoney[userRef.id] += userUpdatedBalance;
                        else accountsMoney[userRef.id] = userUpdatedBalance;

                    });

                    Object.entries(accountsMoney).forEach(([key, val]) => {
                        trans.update(usersCollectionRef.doc(key), {
                            balance: val
                        })
                    });
                    trans.set(mainRef, {
                        balance: mainCollection.balance
                    })

                }
                trans.update(orderRef, {
                    products: updatedProducts,
                    status,
                });
                trans.update(userHistoryRefOrder, { products: updatedProducts, status });

            });
            return {
                status: 200,
                message: "Order status updated successfully.",
            };
        } catch (error) {
            console.error(error)
            return {
                status: 500,
                message: "Failed to update the order status !",
            };
        }
    }

    static async notifyWhenRead(ref) {
        try {
            await AppDB.collection("orders").doc(`${ref.id}`).update({ read: true });
        } catch (error) {
            console.error(error);
        }
    }
}
