
import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyB5Rq4q9rUIMG59PDKlto1EiG4UVgmg_94",
  authDomain: "edenrwanda-13e38.firebaseapp.com",
  projectId: "edenrwanda-13e38",
  storageBucket: "edenrwanda-13e38.appspot.com",
  messagingSenderId: "338690088948",
  appId: "1:338690088948:web:29bdf5e345db2b98bc63a1",
  measurementId: "G-98CB837X8F"
  };

  const App = firebase.initializeApp(firebaseConfig);
  
  const Firebase = App;
  const AppDB = App.firestore();
  AppDB.enablePersistence()
  
export {AppDB, App, Firebase}