import { h, Component } from 'preact';
import { App, Firebase } from '../../db';
import LogModel from '../../models/log';
import Form from '../form';
import Loading from '../loading-C';
import TextField from '../text-field';
import styles from "./style.scss";

export default class Login extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
  

    async _logIn(data){
        this.props.loading(true);
        const res = await LogModel.login(data);
        this.props.loading(false);
        if(res.status !== 200) return alert(res.message);
        this.props.setScreen("app");
        
        
    }

    render() {
       
        return (
            <>
                <div className={styles.AdminSignInContainer}>
                    <div className={styles.signInRow}>
                        <div className={styles.row}>
                            <Form onSubmit={(data) => this._logIn(data)}>
                                <h3>Log in</h3>
                                <TextField className={styles.inputField} label="Email" type="email" name="email" required />
                                <TextField className={styles.inputField} label="Password" type="password" name="password" required />
                                <button disabled={this.state.disabled} type="submit" >Log In</button>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}