import { Component } from 'preact';
import { Router } from 'preact-router';
import { Firebase } from '../db';
import LogModel from '../models/log';
import Caategories from '../routes/categories';
import Contents from '../routes/contents';
import Dashboard from '../routes/Dashboard';
import Orders from '../routes/orders';
import ImageView from '../routes/view-image';
import ProductDetails from '../routes/productDetails';
import Upload from '../routes/upload';
import Users from '../routes/users';
import AppContainer from './app-container';
import Header from './header';
import Loading from './loading-C';
import Login from './login';
import NavBar from './nav-bar';
import OrdersDetails from './orderDetails';

export default class App extends Component {
	constructor() {
		super();
		this.state = {
			isLoading: false,
			currentScreen: "",
			userData: null
		}
	}

	 getLoggedInUserData() {
		Firebase.auth().onAuthStateChanged(async user => {
            if (user) {
				this.loading(true);
				const user = Firebase.auth().currentUser;
				const userData = await LogModel.getLogedInUserData(user.uid);
				this.setState({ userData });
				this.loading(false);
            }
            else {
                this.setState({ currentScreen: "login",  })
            }
        })
	
	}
	componentDidMount() {
		this.getLoggedInUserData();
	}

	componentWillMount(){
		Firebase.auth().onAuthStateChanged(user => {
            if (!user) {
                this.setState({ currentScreen: "logIn" });
            }
            else {
                this.setState({ currentScreen: "app",  })
            }
        })
	}

	loading(status) {
		this.setState({ isLoading: status });
	}

	appInterface() {
		return (
			<>
				<Loading visible={this.state.isLoading} />
				<NavBar loading={status => this.loading(status)} />
				<Header userData={this.state.userData} />
				<AppContainer>
					<Router>
						<Dashboard path="/" />
						<Upload loading={status => this.loading(status)} path="/upload" />
						<Contents loading={status => this.loading(status)} path="/contents" />
						<Orders loading={status => this.loading(status)} path="/orders" />
						<OrdersDetails isLoading={status => this.loading(status)} path="/orders/:orderId"/>
						<ProductDetails path="/contents/:productId" setLoading={status => this.loading(status)} />
						<Caategories loading={status => this.loading(status)} path="/categories" />
						<Users loading={status => this.loading(status)} path="/users" />
						<ImageView isLoading={status => this.loading(status)} path="/image/:imgId" />
					</Router>
				</AppContainer>
			</>
		)
	}

	_toRender() {
		switch (this.state.currentScreen) {
			case "login":
				return (
					<div>
						<Loading visible={this.state.isLoading} /> 
						<Login setScreen={currentScreen => this.setState({ currentScreen })} loading={status => this.loading(status)} />
					</div>
				);
			case "app":
				return this.appInterface();
			default:
				return;
		}
	}

	render(props, { productId }) {

		return <div id="app">{this._toRender()}</div>
	}
}

