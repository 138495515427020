import { h, Component } from 'preact';
import { route } from 'preact-router';
import { loadingCircle } from '../../assets/icons/SVGs';
import OrderModel from '../../models/orders';
import ButtonC from '../button-c';
import Loading from '../loading-C';
import Toast from '../toast';
import styles from "./style.scss";

export default class OrdersDetails extends Component {
    constructor() {
        super();
        this.state = {
            rowData: undefined,
            isLoading: false,
            isProcessingLoading: false,
            isDeliveryLoading: false,
            status: ""
        }
    }

    async ordersDetails() {
        const orderId = this.props.orderId;
        if (!orderId) return;
        this.setState({ isLoading: true });
        const rowData = await OrderModel.getSingleOrder(orderId);
        this.setState({ isLoading: false, rowData });
    }

    componentDidMount() {
        this.ordersDetails();
    }

    async updateStatus(status, givenLoadingState) {
        const ask = confirm(`Do you want update status as ${status}?`);
        if (!ask) return;
        const order = this.state.rowData;
        this.setState({ [givenLoadingState]: true });
        this.props.isLoading(true);
        const res = await OrderModel.updateStatus(status, order);
        this.props.isLoading(false);
        this.setState({ [givenLoadingState]: false });
        if (res.status !== 200) return Toast.create(res.message, { errorMessage: true });
        else Toast.create(res.message, { successMessage: true });
        this.ordersDetails();

    }

    performRejectionAndRestore() {
        const order = this.state.rowData;
        const orderStatus = order.status;
        if (!order.isMomoPay) return;
        const status = orderStatus == "rejected" ? "waitting" : "rejected";
        this.updateStatus(status, "isProcessingLoading");
    }

    render({ }, { rowData, isProcessingLoading, isDeliveryLoading, isLoading }) {
        if (!rowData) return <span></span>;
        const userInfo = rowData.userInfo;
        const location = rowData.locationInfo;
        const isMobileMoney = rowData.isMomoPay;
        const stringfiedLocation = `${location.district} - ${location.sector} - ${location.cell} - ${location.street}`;
        const status = rowData.status;
        const deliveryDisbaled = !!(status == "delivered" || status == "waitting");
        const processingDisabled = !(status == "waitting");
        const processText = status == "delivered" ? "processed" : status == "waitting" ? "process" : status;
        return (
            <>
                <Loading visible={isLoading} />
                <div className={styles.head}>
                    <button type="button" onClick={_ => history.go(-1)}>
                        <svg height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" /></svg>
                    </button>
                    <h2>{userInfo.names}'s order.</h2>
                </div>
                <div className={styles.orderDetailsContainerFluid}>

                    <div className={styles.orderDetailsContents}>
                        <div className={styles.orderRows} >
                            <div className={styles.actionToogleContainer}>
                                {
                                    isMobileMoney
                                        ? (
                                            <>
                                                {
                                                    status == "rejected" || status == "waitting"
                                                        ? <ButtonC onClick={_ => this.performRejectionAndRestore()}
                                                            className={`${styles.processBtn} ${status !== "rejected" ? styles.rejectBtn : ""}`}
                                                            disabled={this.state.isLoading}>{status == "rejected" ? "restore" : "reject"}</ButtonC>
                                                        : ""
                                                }
                                                <ButtonC onClick={_ => route(`/image/${rowData.ref.id}`)} className={`${styles.processBtn}`}
                                                    >View image</ButtonC>
                                            </>
                                        )
                                        : ""
                                }
                                {
                                    status !== "rejected"
                                        ? (
                                            <>
                                                <ButtonC onClick={_ => this.updateStatus("processing", "isProcessingLoading")} className={styles.processBtn}
                                                    disabled={processingDisabled}>{isProcessingLoading ? loadingCircle : processText}</ButtonC>
                                                <ButtonC onClick={_ => this.updateStatus("delivered", "isDeliveryLoading")} className={styles.deliveredBtn}
                                                    disabled={deliveryDisbaled}>{isDeliveryLoading ? loadingCircle : "Delivered"}</ButtonC>
                                            </>
                                        )
                                        : ""
                                }
                            </div>
                            <h3>{rowData.names} order Details</h3>
                            <ul>
                                <div className={styles.content}>
                                    <li className={styles.contentName}>Customer names</li>
                                    <span>:</span>
                                    <li className={styles.contentValue}>{userInfo.names}</li>
                                </div>
                                <div className={styles.content}>
                                    <li className={styles.contentName}>Email</li>
                                    <span>:</span>
                                    <li className={styles.contentValue}>{userInfo.email}</li>
                                </div>
                                <div className={styles.content}>
                                    <li className={styles.contentName}>Address</li>
                                    <span>:</span>
                                    <li className={styles.contentValue}>{stringfiedLocation}</li>
                                </div>
                                <div className={styles.content}>
                                    <li className={styles.contentName}>Phone number</li>
                                    <span>:</span>
                                    <li className={styles.contentValue}>{userInfo.phoneNumber}</li>
                                </div>
                                <div className={styles.content}>
                                    <li className={styles.contentName}>Status</li>
                                    <span>:</span>
                                    <li className={styles.contentValue}>{rowData.status}</li>
                                </div>
                                <div className={styles.content}>
                                    <li className={styles.contentName}>Is payed by momo</li>
                                    <span>:</span>
                                    <li className={styles.contentValue}>{rowData.isMomoPay ? "Yes" : "No"}</li>
                                </div>
                            </ul>

                            <h3 className={styles.products}>Products</h3>

                            {
                                rowData.products.map(data => {
                                    return (
                                        <div className={styles.slideCartItemsParentSection}>
                                            <div className={styles.slideCartItemRow}>
                                                <div className={styles.productImageDetails}>
                                                    <img width="81" height="108" src={data.thumbnail} alt="" />
                                                    <div className={styles.nameandtimes}>
                                                        <h4>{data.productName}</h4>
                                                        <div className={styles.numberAndSign}>
                                                            <span className={styles.multiply}>X</span>
                                                            <span>{data.numberOfItems}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className={styles.slideCartPrice}>
                                                    <h4>{data.price} Frw</h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

