import { App, AppDB } from "../db";

export default class LogModel {
    static async login(data) {
        try {

            const userCredential = await App.auth().signInWithEmailAndPassword(data.email, data.password);

            const userDataToReturn = userCredential.user;

            return {
                status: 200,
                data: userCredential
            };
        } catch (error) {
            console.error(error);
            return {
                status: 500,
                message: "User not Found."
            };
        }
    }

    static async getLogedInUserData(uid){
        try {
        const userData = (await AppDB.collection("admin_users").doc(`${uid}`).get()).data();
            return userData;
        } catch (error) {
            console.error(error);
            return {}
        }
    }
}