import { h, Component } from "preact";
import styles from "./style.scss";
export default class Header extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className={styles.appHeader}>
                <div className={styles.leftSideHeader}>

                </div>
                <div className={styles.rightSideHeader}>
                    <span className={styles.ringIcon}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" /></svg>
                    </span>
                    <div className={styles.userAvatarRow}>
                        <div className={styles.avatarImage}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg>
                        </div>
                        <p className={styles.username}>{this.props.userData ? this.props.userData.username : ""}</p>
                    </div>

                </div>
            </div>
        );
    }
}