import { h, Component } from 'preact';
import { Link } from 'preact-router/match';
import { Firebase } from '../../db';
import styles from "./style.scss";

export default class NavBar extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    async logOut(){
        this.props.loading(true);
        await Firebase.auth().signOut();
        this.props.loading(false);
    }

    render() {
        return (
            <>
                <div className={styles.navContainerFluid}>
                <div className={styles.navigationPositioning}>
                    <div className={styles.container}>
                            <ul className={styles.navListSection}>
                                {/* <li>
                                <Link  activeClassName={styles.active} href="/">
                                    <button className={styles.navBtn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><rect fill="none" height="24" width="24" /><g><path d="M16,11V3H8v6H2v12h20V11H16z M10,5h4v14h-4V5z M4,11h4v8H4V11z M20,19h-4v-6h4V19z" /></g></svg>
                                        <span></span>
                                        <a>Dashboard</a> 
                                    </button>
                                    </Link>
                                </li> */}
                                <li>
                                <Link activeClassName={styles.active} href="/upload">
                                    <button className={styles.navBtn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z" /></svg>
                                        <span></span>
                                        <a>Upload</a>
                                    </button>
                                    </Link>
                                </li>
                                <li>
                                <Link activeClassName={styles.active} href="/contents">
                                    <button className={styles.navBtn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M22 13h-8v-2h8v2zm0-6h-8v2h8V7zm-8 10h8v-2h-8v2zm-2-8v6c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2zm-1.5 6l-2.25-3-1.75 2.26-1.25-1.51L3.5 15h7z" /></svg>
                                        <span></span>
                                         <a>Contents</a>
                                    </button>
                                    </Link>
                                </li>
                                <li>
                                <Link activeClassName={styles.active} href="/orders">
                                    <button className={styles.navBtn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6h-2c0-2.76-2.24-5-5-5S7 3.24 7 6H5c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-7-3c1.66 0 3 1.34 3 3H9c0-1.66 1.34-3 3-3zm7 17H5V8h14v12zm-7-8c-1.66 0-3-1.34-3-3H7c0 2.76 2.24 5 5 5s5-2.24 5-5h-2c0 1.66-1.34 3-3 3z" /></svg>
                                        <span></span>
                                        <a>Orders</a>
                                    </button>
                                    </Link>
                                </li>
                                <li>
                                <Link activeClassName={styles.active} href="/categories">
                                    <button className={styles.navBtn}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2l-5.5 9h11z"/><circle cx="17.5" cy="17.5" r="4.5"/><path d="M3 13.5h8v8H3z"/></svg>
                                        <span></span>
                                        <a>Categories</a>
                                    </button>
                                    </Link>
                                </li>
                                <li>
                                <Link activeClassName={styles.active} href="/users"> 
                                    <button className={styles.navBtn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24" /></g><g><g /><g><path d="M16.67,13.13C18.04,14.06,19,15.32,19,17v3h4v-3C23,14.82,19.43,13.53,16.67,13.13z" /><path d="M15,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4c-0.47,0-0.91,0.1-1.33,0.24C14.5,5.27,15,6.58,15,8s-0.5,2.73-1.33,3.76 C14.09,11.9,14.53,12,15,12z" /><path d="M9,12c2.21,0,4-1.79,4-4c0-2.21-1.79-4-4-4S5,5.79,5,8C5,10.21,6.79,12,9,12z M9,6c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2 S7,9.1,7,8C7,6.9,7.9,6,9,6z" /><path d="M9,13c-2.67,0-8,1.34-8,4v3h16v-3C17,14.34,11.67,13,9,13z M15,18H3l0-0.99C3.2,16.29,6.3,15,9,15s5.8,1.29,6,2V18z" /></g></g></svg>
                                        <span></span>
                                        <a>Users</a>
                                    </button>
                                    </Link>
                                </li>
                            </ul>
                            <div className={styles.logOutBtn}>
                            <button onClick={_=>this.logOut()} >
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"/></g></svg>
                            </button>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </>
        );
    }
}